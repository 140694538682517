export const CONFIG = {
    publihserList: [
        {
            "publisher": "kenpath",
            "domain": "engagemax.kenpath.ai"
        },
        {
            "publisher": "parismodamagazine",
            "domain": "www.parismodamagazine.com"
        },
        {
            "publisher": "dfashionmagazine",
            "domain": "www.dfashionmagazine.com"
        },
        {
            "publisher": "latinofashionmagazine",
            "domain": "www.latinofashionmagazine.com"
        }
    ],
    widgetEventOrder: [
        "Number of Widget Loads",
        "Number of individual slides viewed",
        "Total Number of Ads Loaded",
        "Total Number of Ads Completed",
        "Number of Skips"
    ]
}

