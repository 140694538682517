import Keycloak from 'keycloak-js'
const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  //url: "http://localhost:8080/auth",
  realm: 'dashboard-engagemax',
  clientId: process.env.REACT_APP_REALM_CLIENT_ID,
})

export default keycloak


