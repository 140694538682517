import Header from "../header/header";
import Filter from "../sidebar/filter"
import "../../App.css"
import "./dashboard.css"
import { Chart } from "../shared/chart";
import { TableComp } from "../shared/tableData";
import { useEffect, useState } from "react";
import axios from "axios";
import { endPoints } from "../../utils/endPoint";
import { BigNumberChart } from "../shared/bigNumberChart";
import Loader from "../shared/loader";
import { format, startOfMonth } from 'date-fns';
import { useKeycloak } from '@react-keycloak/web'
import PublisherChart from "../shared/publisherChart";
import PublisherDataTable from "../shared/publisherTable";
import { CONFIG } from "../../constants";


export default function Dashboard() {
  const { keycloak, initialized } = useKeycloak();
  const [publisherList, setPublisherList] = useState([]);
  const [publisherDomainsList, setPublishersDomainList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [aggregatedData, setAggregatedData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [pubisherName, setPublihserName] = useState();
  const [bigNumberChart, setBigNumberChart] = useState();
  useEffect(() => {
    let admin = false;
    if (keycloak.idTokenParsed && keycloak.idTokenParsed.userGroup) {
      let userGroup = keycloak.tokenParsed.userGroup;
      const publisherList = CONFIG.publihserList;
      const publishersDomainList = publisherList.map(item => item.domain);
      userGroup = userGroup.sort();
      let publisherDomain;
      console.log(userGroup);
      
      if (userGroup.includes("admin")) {
        admin = true;
        setIsAdmin(true);
        setPublisherList(publisherList);
        setPublishersDomainList(publishersDomainList);
      }
      else {
        userGroup = publisherList.filter(item => userGroup.includes(item.publisher));       
        if (userGroup.length > 0) {
          publisherDomain = userGroup[0].domain;
          setPublihserName(userGroup[0].domain); // taking the first value initally
          setPublisherList(userGroup)
        }
      }
      let body = {
        filters: {
          created_at: {
            startDate: `${format(startOfMonth(new Date()), "yyyy-MM-dd 00:00:00.000")}`,
            endDate: `${format(new Date(), "yyyy-MM-dd 23:59:59.999")}`
          },
          publishers: admin ? publishersDomainList : [publisherDomain]
        },
      }
      axios.post(`${process.env.REACT_APP_LOCAL_API_URL + endPoints.getAllRecords}`, {
        filters: body.filters
      }).then(response => {
        setAggregatedData(response?.data);
        const aggregatedData = {};

        const sortedData = response.data.sort((a, b) => {
          return CONFIG.widgetEventOrder.indexOf(a.event_mode) - CONFIG.widgetEventOrder.indexOf(b.event_mode);
        });
        sortedData.forEach(obj => {
          const { event_mode, details, total_count } = obj;

          if (aggregatedData[event_mode]) {
            aggregatedData[event_mode].total_count += total_count;
            aggregatedData[event_mode].details.push(...details);
          } else {
            aggregatedData[event_mode] = {
              event_mode,
              total_count,
              details: [...details]
            };
          }
        });
        
        // Convert aggregatedData object to array
        const result = Object.values(aggregatedData);
        setBigNumberChart(result)
        setIsLoading(false);
      })
    }
  }, [keycloak.idTokenParsed])
   
  
  const filters = async (filters) => {
    setIsLoading(true);
    setBigNumberChart(null);
    let body = {
      filters,
    }
    if (isAdmin) {
      if (!filters.hasOwnProperty('publishers')) {
        body.filters.publishers = publisherDomainsList
      }
    }
    if (!isAdmin) {
      if (filters && !filters.hasOwnProperty('publishers')) {
        body.filters['publishers'] = [pubisherName]
      }
    }
    let response = await axios.post(`${process.env.REACT_APP_LOCAL_API_URL + endPoints.getAllRecords}`, {
      filters: body.filters
    })

    let aggregateData = {};
    const sortedData = response.data.sort((a, b) => {
      return CONFIG.widgetEventOrder.indexOf(a.event_mode) - CONFIG.widgetEventOrder.indexOf(b.event_mode);
    });
    sortedData.forEach(obj => {
      const { event_mode, details, total_count } = obj;

      if (aggregateData[event_mode]) {
        aggregateData[event_mode].total_count += total_count;
        aggregateData[event_mode].details.push(...details);
      } else {
        aggregateData[event_mode] = {
          event_mode,
          total_count,
          details: [...details]
        };
      }
    })
    console.log(aggregateData);
    
    setAggregatedData(response?.data);
    const result = Object.values(aggregateData);
    setBigNumberChart(result)
    setIsLoading(false);
  }

  return (
    <div className="dashboard bg-light">
      <div className="dashboard__container">
        <h1>Dashboard Engagemax</h1>
        <div className="mb-10">
          <Filter publisherList={publisherList} filters={filters} role={isAdmin} />
        </div>
        {isLoading ? <Loader /> : aggregatedData &&
          <div style={{ marginTop: "25px" }}>
            <div className="container bg-white mb-3 rounded shadow-lg">
              <div className="big-number-chart">
                <div className="d-flex flex-wrap justify-content-around">
                  {bigNumberChart && bigNumberChart.map(data => {
                    return (
                      <BigNumberChart title={data.event_mode} value={data && data.total_count ? data.total_count : 0} />
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="container table-section bg-white mb-3 rounded shadow-lg" style={{ padding: "40px" }}>
              <div><h3>Widget Footprint By Date</h3></div>
              {aggregatedData.length ? <TableComp data={aggregatedData} /> : <p>No Data available for the selected Date</p>}
            </div>
            <div className="container bg-white mb-3 p-3 shadow-lg">
              <div><h3>Widget Footprint By Date</h3></div>
              {aggregatedData.length ? <Chart data={aggregatedData} /> : <p>No Data available for the selected Date</p>}
            </div>

            <div className="container table-section bg-white mb-3 rounded shadow-lg" style={{ padding: "40px" }}>
              <div><h3>Widget Footprint By Publisher</h3></div>
              {aggregatedData.length ? <PublisherDataTable data={aggregatedData} /> : <p>No Data available for the selected Date</p>}
            </div>
            <div className="container bg-white mb-3 p-3 shadow-lg">
              <div><h3>Widget Footprint By Publisher</h3></div>
              {aggregatedData.length ? <PublisherChart data={aggregatedData} /> : <p>No Data available for the selected Date</p>}
            </div>
          </div>
        }
      </div>
    </div>
  )
}
